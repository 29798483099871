.streaming-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  .list-panel-main-content {
    .kraken-player-output-link.kraken-player-output-link.kraken-player-output-link {
      pointer-events: auto;
      font-size: inherit;
      padding: 0;
    }
  }
}

.streaming-placeholder {
  position: absolute;
  top: -24px;
  left: 0;
  padding-top: 1rem;
  width: 100%;
}
