.kraken-network {
    .side-button {
        flex: 1;
        display: flex;
        align-self: flex-end;
        justify-content: flex-end;
    }
    
    div.HaiFormSection {
        &.static-routes-table {
            padding: 0;
            margin-bottom: 2rem;
            overflow: hidden;
            &.empty {
                display: none;
            }
        }
    }

    .HaiFormSection.formSectionDark.snmp-section {
        margin-bottom: 1rem;

        .snmp-add-button {
            margin-bottom: 2rem;
        }
    }

    .HaiFormGroup[theme='argon'].formcontrol-append-icon.row {
        display: grid;
        grid-template-columns: auto 0 0;
        width: calc(100% - 34px);
        gap: 8px;
        align-items: center;
        svg {
            cursor: pointer;
            float: right;
        }

        .append-icon {
            margin-bottom: 1.5rem;
        }
    }

    .static-routes-datatable-row {
    
        td {
            div {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        td.data-table-cell:nth-child(2) {
            width: 45%;
        }
    }
}