@import '/src/assets/scss/theme.scss';

.stream-report-panel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  max-height: 320px;

  .graphs-section-content.short {
    width: 70%;
  }
}

.chart-mid-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 1rem;
  justify-content: flex-end;
}

.kraken-status-spinner.kraken-status-spinner.kraken-status-spinner {
  width: 14px;
}

.stream-report-panel-section {
  width: 30%;
  position: relative;

  .HaiHeader[theme='argon'][type='panel-header'].panel-header {
    border-radius: 0.25rem;
    text-align: center;
    color: $haiui-gray-07;
    font-size: 13px;
  }

  .panel-section-stats {
    display: flex;
    margin: 1rem 1rem 0rem 1rem;

    .stats-left {
      color: $haiui-gray-07;
      width: 40%;
    }

    .stats-right {
      color: $haiui-white;
      width: 60%;
    }
  }

  .stats-buttons {
    position: absolute;
    bottom: -1rem;
    right: -1rem;
    display: flex;
    gap: 0.5rem;
  }
}
