@import '/src/assets/scss/theme.scss';

.archive-stats {
  .progress-bar-label {
    color: $haiui-gray-07;
    display: block;
    margin-bottom: 0.5rem;
    margin-top: 1rem;

    &.first {
      margin-top: 0;
    }
  }
}

.edit-archive {
  .form-container-lg {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .form-container-md {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .form-container-sm {
    display: block;
  }

  .button-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 1rem;
    margin-bottom: 0.5rem;
  }
}

.add-archive {
  .with-margin-bottom {
    .HaiFormControlContainer {
      margin-top: 0;
      margin-bottom: 1.25rem;
    }
  }
}
