@import '/src/assets/scss/theme.scss';

.no-content {
  -webkit-box-align: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 80%;
  margin-top: 8rem;
  .icon {
    fill: $kraken-teal;
  }
  .HaiHeader[theme='argon'][type='icon-header']
    .icon-header-title-container
    .icon-header-title
    span {
    font-size: 40px;
  }
  .detail-text {
    color: $haiui-gray-07;
  }
  .centered {
    text-align: center;
  }
}
