@import '/src/assets/scss/theme.scss';

.processor-layout {
  .separator {
    height: 1px;
    background-color: $haiui-gray-05;
    width: 100%;
    margin: 1rem 0 1rem 0;
  }
  .processor-text.processor-text.processor-text {
    color: $haiui-gray-08;
  }
  .processor-empty {
    padding: $haiui-spacing-04 0;
  }

  .select-processor {
    -webkit-box-align: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 80%;
    margin-top: 2rem;
    .icon {
      fill: $kraken-teal;
    }
    .ring-1 {
      width: 124px;
      height: 124px;
      border-radius: 248px;
      border: 0.188rem solid $haiui-gray-03;
    }
    .ring-2 {
      width: 99px;
      height: 99px;
      border-radius: 198px;
      border: 0.188rem solid rgba(60, 61, 72, 0.5);
    }
    .ring-3 {
      width: 76px;
      height: 76px;
      border: 0.188rem solid rgba(60, 61, 72, 0.25);
      border-radius: 152px;
    }
    .ring {
      display: grid;
      place-items: center;

      -webkit-background-clip: padding-box; /* for Safari */
      background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
    }
    .HaiHeader[theme='argon'][type='icon-header']
      .icon-header-title-container
      .icon-header-title
      span {
      font-size: 40px;
    }
    .detail-text {
      color: $haiui-gray-07;
    }
    .centered {
      text-align: center;
    }
  }
}
