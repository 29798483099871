@import '/src/assets/scss/theme.scss';

.passwordExpired {
  .buttonRow .btn {
    width: 100%;
  }
}

.teal {
  fill: $kraken-teal;
}

.password-apply {
  display: block;
}
