.settings-services {
  div.HaiFormSection {
    &.service-table {
      padding: 0;
      margin-bottom: 2rem;
      overflow: hidden;
    }
  }

  .services-datatable-row {
    td {
      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    td.data-table-cell:nth-child(1) {
      width: 80%;
    }
    td.data-table-cell.icon.pointer {
      width: 100px;
      > div {
        padding-left: 10px;
        justify-content: flex-start;
      }
    }

    td.data-table-cell.switch {
      div {
        overflow: visible;
      }
    }
    &.hideCheckbox {
      td.data-table-cell.switch > div {
        visibility: hidden;
      }
    }
  }
}
