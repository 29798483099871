@import '/src/assets/scss/theme.scss';

.security-updates {
  .HaiFormSection.formSectionDark {
    &.current-section {
      padding-bottom: 0.5rem;
      margin-bottom: 1rem;
    }

    &.update-section {
      padding-bottom: 3rem;

      .upload-container {
        position: relative;
        width: 100%;

        .uploading-footer {
          position: absolute;
          bottom: 0;
          display: flex;
          width: 100%;
          justify-content: space-evenly;
          flex-direction: column;
          align-items: center;
          gap: 1rem;

          .warning-msg {
            color: $haiui-gray-08;
          }
        }
      }

      .update-buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 1rem;
        margin-top: 2rem;
      }
    }
  }
}

.update-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  .update-info {
    display: flex;
    flex-direction: row;
    gap: 0.4rem;
  }

  .info-version {
    margin-bottom: 0.3rem;
    text-align: center;
  }

  .update-progress {
    margin-top: 2rem;
    .progress-item {
      padding: 5px;
    }
  }
  .subtitle.subtitle.subtitle {
    margin-bottom: 0;
    margin-top: 10px;
  }
  .server-updated {
    margin-bottom: 1rem;
    color: $haiui-gray-08;
  }
}
