@import '~bootstrap-less-port/dist/css/bootstrap.css';
@import './theme.scss';

html,
body {
  height: 100%;
  background-color: $haiui-gray-01;
  font-size: 16px;
  overflow: hidden;
  margin: 0;
}

* {
  -webkit-font-smoothing: antialiased;
}

// HACK - haiui has a margin bottom 0 if there is only one item on the row, otherwise its a 2rem margin
// HAIUI-1061
.HaiModalDialog[theme='argon']
  .HaiModal
  .HaiModalBody
  .HaiForm
  .form-group.row.form-group.row.form-group.row:last-child {
  margin-bottom: 4px;
}

#root {
  height: 100%;
  display: unset;
}

// This is from bootstrap
.d-none {
  display: none !important;
}

//HAI HAX
.HaiSearchInput {
  .search-input-tags {
    .tag-text-container {
      .text.text-truncate {
        max-width: 100px;
      }
    }
  }
}

//HAI HAX
.HaiListPanelTitle {
  pointer-events: none;
}

//HAI HAX
.HaiListActionBar {
  min-height: 40px;
}
//HAI HAX
.HaiTabs[theme='argon'].HaiTabs[theme='argon'] {
  padding: 1.75rem 0 0;
}
//HAI HAX
.HaiModal .HaiTypeaheadListContainer.expanded.dropdown-menu {
  max-width: 280px;
}

//HAI HAX: fixes page shift after collapsing the sidebar
.HaiSidebar.collapse-sidebar.collapse-force {
  width: 6rem;
  -webkit-transition: width 0.125s ease;
  -moz-transition: width 0.125s ease;
  transition: width 0.125s ease;
  transition-delay: 0s;
}

.HaiModalDialog.HaiDialog[theme='argon']
  .modal-content
  .HaiModal
  .HaiModalHeader.modal-header.modal-header.modal-header {
  padding-bottom: 2rem;
}

.HaiFormControlErrorContainer .HaiFormControlError.HaiFormControlError.HaiFormControlError {
  font-size: 10px;
}

.HaiFormSectionContainer[theme='argon'] .HaiFormSection.formSectionDark {
  background-color: $charcoalGray;
}

.haiui-note-text {
  color: $haiui-gray-06;
  font-size: 12px;
}

.tnum {
  font-feature-settings: 'tnum';
}
.tnum-zero {
  font-feature-settings: 'tnum', 'zero';
}
.HaiSidebarContainer .HaiSidebar {
  .HaiSidebarNav.HaiSidebarNav.HaiSidebarNav {
    background-color: #25262b;
  }
}

// Webkit Scrollbar overrides
.scrollable {
  &::-webkit-scrollbar {
    position: absolute;
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    border-radius: 3px;
    padding: 60px 0;
  }
  &::-webkit-scrollbar-thumb {
    background: $haiui-gray-05;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $haiui-gray-07;
  }
}
.kraken-span-2 {
  grid-column-start: 1;
  grid-column-end: 3;
}

.kraken-span-3 {
  grid-column-start: 1;
  grid-column-end: 4;
}

.hai-hack-HAIUI-1325 {
  overflow: hidden;
}

.form-info-text {
  .privacy-policy-link.privacy-policy-link.privacy-policy-link {
    font-size: 12px !important;
    padding: 0;
    height: 1rem;
  }
}
.modal-body .fullstory-desc {
  .privacy-policy-link.privacy-policy-link.privacy-policy-link {
    padding: 0;
  }
}
// Prevent scrollbar from hiding and page content shifting
.modal-open .modal .modal-dialog .modal-content .HaiModal .HaiModalBody {
  overflow-y: auto;
}
// prevent page from scrolling when scrolling in a modal
.modal-open .modal {
  overflow-y: hidden;
}

.streaming-container .clickable,
.tab-page-container .clickable {
  .HaiListThumbnail {
    pointer-events: none;
  }
  .list-panel-main-content span {
    pointer-events: none;
  }
}

.checkbox-form-item.checkbox-form-item > .HaiFormControlContainer {
  margin-top: 0;
  margin-bottom: 1.4375rem;
}
