.form-input-viewonly {
  width: 100%;
  > div {
    text-overflow: ellipsis;
    width: 100%;
  }
}
.HaiLabelValue.label-value.with-icon {
  .labelValue {
    text-overflow: ellipsis;
    width: 100%;
    justify-content: space-between;
    .icon {
      margin-right: 0;
    }
  }
}
