.security-certificates {
  .cert-icon {
    top: -1px;
    position: relative;
  }

  .HaiDataTable[theme='argon'].content {
    .HaiDataTableTitleBar {
      padding: initial;
      background-color: transparent;
    }
  }

  .buttons {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .ca-datatable-row {
    td:nth-child(3) {
      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    td:nth-child(3) {
      max-width: 380px;
      @media screen and (max-width: 1300px) {
        max-width: 200px;
      }
    }
  }
}

.generate-cert-form {
  .with-margin-bottom {
    .HaiFormControlContainer {
      margin-top: 0;
      margin-bottom: 1.25rem;
    }
  }

  .new-key-warning {
    margin-bottom: 1.25rem;
  }
}

.import-certificate {
  .HaiFormGroup[theme='argon'].with-validation.input-file-upload {
    margin-bottom: 1.5rem;
    flex-wrap: nowrap;
  }
}
