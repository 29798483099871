body {
  background-image: url('/assets/images/Kraken-Sign-In-Background.jpg');
  background-size: cover;
  background-blend-mode: soft-light;
}
.not-found {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  width: 100%;
}

.text-section {
  .kraken {
    padding-bottom: 48px;
  }
  .devoured {
    padding-bottom: 48px;
  }
  .t404 {
    padding-bottom: 20px;
  }
  .details {
    width: 75%;
    padding-bottom: 80px;
  }
}

.static-image {
  position: fixed;
  background-size: cover;
}

@media screen and (max-width: 979px) {
  .text-section {
    width: 100%;
    padding-left: 40px;
  }
}

@media screen and (min-width: 980px) {
  .text-section {
    width: 58%;
  }

  .squid-container {
    position: fixed;
    width: 100%;
    bottom: 0%;
    left: 0%;
    height: 680px;
    .squid {
      width: 500px;
      min-height: 680px;
      bottom: 0%;
      left: -120px;
    }
    .squid-body {
      background-image: url('/assets/images/pageNotFound/squid-body.png');
    }
    .squid-arm-1 {
      animation-duration: 6s;
      animation-name: wiggle;
      background-image: url('/assets/images/pageNotFound/squid-arm-1.png');
    }
    .squid-arm-2 {
      // Arm holding binocular
      animation-duration: 20s;
      animation-name: wiggle2;
      background-image: url('/assets/images/pageNotFound/squid-arm-2.png');
    }
    .squid-arm-3 {
      animation-duration: 5s;
      animation-name: wiggle;
      background-image: url('/assets/images/pageNotFound/squid-arm-3.png');
    }
    .squid-bg {
      height: 700px;
      width: 360px;
      bottom: -20px;
      left: 0;
      background-image: url('/assets/images/pageNotFound/squid-bg.png');
    }
    .binocular {
      height: 130px;
      width: 320px;
      bottom: 400px;
      left: 93px;
      background-image: url('/assets/images/pageNotFound/binocular.png');
    }
  }
}

// Shrink binocular
@media screen and (min-width: 1280px) {
  .squid-container {
    .binocular {
      height: 175px;
      width: 430px;
    }
  }
}
@media screen and (min-width: 1444px) {
  .squid-container {
    .binocular {
      height: 200px;
      width: 500px;
    }
  }
}

@media screen and (min-width: 2000px) {
  .squid-container {
    .squid {
      width: 1000px;
      min-height: 1360px;
      bottom: 0%;
      left: -120px;
    }
    .squid-bg {
      height: 1400px;
      width: 720px;
    }
    .binocular {
      height: 220px;
      width: 550px;
      bottom: 800px;
      left: 286px;
    }
  }
}

@media screen and (min-width: 2622px) {
  .squid-container {
    .binocular {
      height: 320px;
      width: 800px;
    }
  }
}

@media screen and (min-width: 3222px) {
  .squid-container {
    .binocular {
      height: 400px;
      width: 1000px;
    }
  }
}

.binocular {
  animation-name: search;
  transform-origin: center left;
  animation-duration: 20s;
}
.animateloop {
  animation-name: search;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

// Animations
@keyframes search {
  from {
    transform: rotate(5deg) skewX(-5deg);
  }
  to {
    transform: rotate(-5deg) skewX(5deg);
  }
}

@keyframes wiggle {
  from {
    transform: rotate(1deg);
  }
  to {
    transform: rotate(-1deg);
  }
}

@keyframes wiggle2 {
  from {
    transform: rotate(2deg) skewX(10deg);
  }
  to {
    transform: rotate(-1deg) skewX(-10deg);
  }
}
