@import '/src/assets/scss/theme.scss';

.tab-page-container {
  width: 100%;
  overflow-y: scroll;
  padding: 3rem 16px 0;
  @media screen and (min-width: 1500px) {
    padding: 3rem 35px 0;
  }
  @media screen and (min-width: 1674px) {
    padding: 3rem 65px 0;
  }
  background-color: $haiui-gray-00;

  .tab-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .tab-header-section-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.8rem;

    .tab-header-icon {
      fill: $kraken-teal;
    }

    .tab-header-preset-icon {
      fill: $haiui-white;
    }
  }

  .tabs.full-width {
    padding-left: 0;
    padding-right: 0;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  .tab-container {
    position: relative;
    height: 80px;
  }
  .tab-placeholder {
    position: absolute;
    height: 50px;
    width: 100%;
    left: -40px;
    padding: 2.75rem 0 0.5rem 2.75rem;
    display: grid;
  }
}
