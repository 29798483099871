@import '/src/assets/scss/theme.scss';

.stream-stats {
    height: 100%;

    .separator {
        height: 1px;
        background-color: $haiui-gray-05;
        width: 100%;
        margin: 1rem 0 1rem 0;
    }

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .buttons {
            display: flex;
            gap: 1rem;
        }
    }

    .go-back.link{
        font-size: 12px;
    }
}