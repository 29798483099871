.security-policies {

    .policies-container {
        display: flex;
        gap: 5rem;
        flex-wrap: wrap;
    }

    .HaiFormSectionContainer[theme='argon']  {
        margin-top: 1rem;
        .HaiFormSection {
            padding-bottom: 3rem;
        }
    }
}
