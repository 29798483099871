@import '/src/assets/scss/theme.scss';

.HaiForm[theme='argon'] .HaiFormGroup[theme='argon'] {
  .formcontrol-append-icon {
    display: grid;
    grid-template-columns: auto 0 0;
    width: calc(100% - 34px);
    gap: 8px;
    align-items: center;
    svg {
      cursor: pointer;
      float: right;
    }
  }
  .form-input-viewonly {
    width: 100%;
    > div {
      text-overflow: ellipsis;
      width: 100%;
    }
  }
  .full-width {
    width: 100%;
  }
}

.outputs-tags .dropdown-container {
  .tags-container {
    .HaiTag {
      display: inline-flex;
      width: 100%;
      background-color: $haiui-gray-01;
      border-radius: 0;
      &.active {
        background-color: $haiui-gray-02;
        .text-truncate {
          color: $haiui-gray-08;
        }
      }
      &:hover {
        background-color: $haiui-gray-05;
      }
      svg.active-icon {
        position: absolute;
        left: 0px;
        fill: $haiui-green-01;
      }
      .tag-text-container {
        left: 30px;
        position: absolute;
      }
      svg.add-icon {
        left: 0px;
        height: 15px !important;
        position: absolute;
        top: 7px;
      }
    }
  }
  .action-bar.action-bar.action-bar {
    display: none;
  }
}
