@import 'assets/scss/theme.scss';

.stats-panel-container {
  background-color: $haiui-gray-01;
  border-radius: 8px;
  position: relative;

  .header {
    height: 32px;
    background-color: $haiui-gray-02;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    .header-title {
      color: $haiui-gray-07;
      font-size: 13px;
    }
  }

  .bottom-filler {
    height: 18px;
    width: 100%;
    background-color: $haiui-gray-01;
    position: absolute;
    bottom: -12px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .content {
    padding: 0.5rem 1.5rem 1.5rem;

    .content-item {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      margin-top: 1rem;
      overflow: hidden;

      .right {
        font-size: 13px;
        width: 40%;
      }

      .left {
        width: 60%;
        color: $haiui-gray-06;
        font-size: 13px;
      }
    }
  }
}
