@import '/src/assets/scss/theme.scss';

.stats-header-panel {
  background-color: $haiui-gray-01;
  border-radius: 8px;
  margin: 1rem 0;

  .HaiGraph[theme='argon'] .graph-container {
    position: relative;
    width: 100%;
    height: unset;
  }

  .header {
    height: 64px;
    background-color: $haiui-gray-02;
    display: flex;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .status-indicator {
      height: 100%;
      width: 4px;
      margin-right: 27px;
    }

    .header-title {
      color: $haiui-white;
      font-size: 28px;
      margin-left: 12px;
    }
  }

  .content {
    padding: 0.8rem;
    display: flex;
    gap: 1rem;

    .graphs-section-content {
      width: 65%;
      align-self: center;
      height: 100%;

      &.center {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .thumbnail-section {
      width: 35%;
      .description {
        margin: 0.8rem 0;
        font-size: 11px;
      }
      .thumbnail {
        width: 100%;
        border-radius: 4px;
        margin: 1.2rem 0;
      }
    }
  }

  @media only screen and (max-width: 1300px) {
    .content {
      flex-direction: column;
      .graphs-section-content {
        width: 100%;
      }
      .thumbnail-section {
        width: 100%;
      }
    }
  }
}
