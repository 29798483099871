.masonry {
  display: grid;
  grid-gap: 1rem;
  grid-row-gap: 0px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-auto-rows: 1px;
  &.masonry-single {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    .stats-panel-container .content .content-item .left {
      min-width: 30%;
      width: inherit;
    }
  }
}

@media only screen and (max-width: 1300px) {
  .masonry {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media only screen and (max-width: 1000px) {
  .masonry {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
