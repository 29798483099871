@import '/src/assets/scss/theme.scss';

$dashboardGap: 24px;
$ringGap: 34px;
$mediaQueryLarge: 1440px;
$mediaQueryXLarge: 1600px;
$mediaQueryMedium: 1250px;
$mediaQuerySmall: 912px;

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.dashboard.dashboard.dashboard {
  min-width: 730px;
  .HaiPanel {
    .header {
      white-space: nowrap;
    }
    .slidingpanel-container {
      overflow: hidden;
    }
  }

  .thumbnail-content-wrapper {
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .card-stats-row {
    display: grid;
    grid-gap: $dashboardGap;
    gap: $dashboardGap;
    grid-template-columns: 1.2fr 0.8fr 0.8fr 1fr;
    padding-top: $dashboardGap;
    .HaiCard {
      width: 100%;
      min-width: 9.5rem;
    }
  }
  .card-title-container {
    white-space: nowrap;
    overflow: hidden;
  }
  .dash-card {
    height: 126px;
    margin: 0 auto;
    display: flex;
    grid-gap: $dashboardGap;
    .HaiGraph.multi-axes {
      opacity: 1;
      animation-name: fadeInOpacity;
      animation-iteration-count: 1;
      animation-timing-function: ease-in;
      animation-duration: 0.3s;
      transition: width 0.1s ease-in-out;
    }
    .HaiProgressRing {
      margin-top: 12px;
    }
  }
  .grid-streams {
    display: grid;
    grid-gap: $dashboardGap;
    gap: $dashboardGap;
    grid-template-columns: 1fr 1fr;
    padding-top: $dashboardGap;
  }

  .stats-card {
    > div {
      height: 100%;
      width: 100%;
      grid-template-columns: 1fr;
      display: grid;
    }
    margin: 0 $dashboardGap;
    grid-gap: 0 $dashboardGap;
    gap: 0 $dashboardGap;
    white-space: nowrap;
    .text-label {
      padding-right: 5px;
    }
  }

  @media screen and (max-width: $mediaQuerySmall) {
    .grid-streams {
      grid-template-columns: 1fr;
    }
  }

  @media screen and (max-width: $mediaQueryMedium) {
    .card-stats-row {
      grid-template-columns: repeat(3, 1fr);
      .card-graph {
        grid-column-start: 1;

        grid-column-end: 4;
      }
      .card-progress-wheel {
        grid-column-start: 1;
      }
    }
  }

  @media screen and (max-width: $mediaQueryMedium) {
    .dashboardLargeView {
      display: none;
    }
    .stats-card > div {
      grid-template-columns: 1fr;
    }
  }

  @media screen and (min-width: $mediaQueryMedium) {
    .stats-card > div {
      grid-template-columns: 1fr 1fr;
    }
    .grid-streams {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .dashboardLargeView {
      display: initial;
    }
  }

  @media screen and (max-width: $mediaQueryLarge) {
    .card-stats-row {
      grid-template-columns: repeat(6, 1fr);
      .HaiCard {
        grid-column-start: 1;
        grid-column-end: 6;
      }

      .HaiCard:nth-child(1) {
        grid-row: 1;
        grid-column: 1 / 5;
      }
      .HaiCard:nth-child(2) {
        grid-row: 2;
        grid-column: 1 / 4;
      }
      .HaiCard:nth-child(3) {
        grid-row: 2;
        grid-column: 4 / 7;
      }

      .HaiCard:nth-child(4) {
        grid-row: 1;
        grid-column: 5 / 7;
      }

      .card-progress-wheel {
        grid-column-start: 1;
      }
    }
  }
  .thumbnail-content-wrapper {
    height: 100%;
  }

  @media screen and (min-width: $mediaQueryXLarge) {
    .grid-streams {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .section-content.ms-graph-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    line {
      display: none;
    }

    .title-container {
      float: right;
      right: 25px;
    }
  }

  .slidingpanel-container .absolute-content-wrapper .content-container {
    overflow: hidden;
  }

  .HaiPanel {
    .subtitle,
    .title-text {
      font-family: HCo Gotham SSm Book, Helvetica, Arial, sans-serif;
    }
  }
}
