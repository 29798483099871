@import '/src/assets/scss/theme.scss';

.settings-licensing {
  div.HaiFormSection {
    &.header-section {
      margin-bottom: 2rem;
    }
  }

  div.HaiFormSection {
    &.features-section {
      padding: 0;
      margin-bottom: 2rem;
      overflow: hidden;
    }
  }

  .licensing-datatable-row {
    td {
      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    td.data-table-cell:nth-child(1) {
      width: 80%;
    }
    td.data-table-cell.icon.pointer {
      width: 20%;
      div {
        padding-left: 14px;
        justify-content: flex-start;
      }
    }
  }

  .form-container-lg {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .form-container-md {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .form-container-sm {
    display: block;
  }

  .button-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 1rem;
    margin-bottom: 0.5rem;
  }

  .grid-column {
    &.span1 {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  .update-section {
    margin-bottom: 2rem;

    &.HaiFormSection.formSectionDark {
      padding-bottom: 3rem;
    }

    .update-msg {
      color: $haiui-gray-07;
      display: block;
      margin-bottom: 0.5rem;
    }

    .update-input {
      display: flex;
      gap: 3rem;
      flex-direction: row;
      @media screen and (max-width: 1000px) {
        flex-direction: column;
        gap: 1rem;
        align-items: flex-end;
      }

      .HaiTextInlineEditor[theme='argon'],
      .HaiTextInlineEditor[theme='argon'].editContainer > textarea {
        overflow: unset;
        white-space: unset;
      }
    }

    .update-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 1rem;
      margin-top: 2rem;
    }
  }
}
