.settings-reporting {
  .HaiFormSection.formSectionDark {
    &.status-section {
      padding-bottom: 3rem;
      margin-bottom: 2rem;
    }
  }

  div.HaiFormSection {
    &.events-section {
      padding: 0;
    }
  }

  .reporting-datatable-row {
    td {
      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    td.data-table-cell:nth-child(1) {
      div {
        min-width: 200px;
        max-width: 300px;
      }
      @media screen and (max-width: 1300px) {
        div {
          max-width: 150px;
        }
      }
    }

    td.data-table-cell:nth-child(2) {
      max-width: 600px;
      @media screen and (max-width: 1300px) {
        max-width: 200px;
      }
    }
  }

  .reporting-pagination {
    justify-content: center;
    margin: 1rem;
    .inactive-container {
      margin-left: 12px;
      margin-right: 12px;
    }
  }

  .buttons {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    padding: 0.5rem 1rem;
  }
  .toggle-label {
    user-select: none;
    display: inline;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    font-size: 0.875rem;
    color: #ffffff;
    font-family: 'HCo Gotham SSm Book', Helvetica, Arial, sans-serif;
    line-height: 1rem;
  }
}
