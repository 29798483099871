.kraken-shutdown {
  width: 100%;
  height: 100%;
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
