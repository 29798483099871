.text-label {
  .label {
    font-family: 'HCo Gotham SSm Medium', Helvetica, Arial, sans-serif;
    font-size: 12px;
    color: #6d6f81;
    white-space: nowrap;
    padding-top: 12px;
  }
  .info {
    font-size: 14px;
    color: #dadae6;
  }
}
