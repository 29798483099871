@import '/src/assets/scss/theme.scss';

.tab-header-container {
  .HaiDropdownContainer {
    padding-top: 4px;
    .hai-dropdown-toggle-btn.hai-dropdown-toggle-btn.hai-dropdown-toggle-btn {
      &.btn-secondary {
        font-size: 18px;
        padding-top: 3px;
      }
    }
  }
}
.datatable-header-bulk-actions-container.datatable-header-bulk-actions-container.datatable-header-bulk-actions-container {
  overflow: hidden;
  .bulk-action-btn {
    padding-right: 1em;
  }
}

body
  .HaiDropdownMenu[theme='argon']
  .item-focus-wrapper:not(.multiSelect):not(.buttonToggle)
  .HaiDropdownMenuItem.separator.separator.separator {
  border-top: 2px solid $haiui-gray-02;
}

.withCheckmark > .checkIcon > label:has(svg) {
  display: flex;
  .startup-tag {
    height: 19px;
  }
}

.preset-dropdown .multilevel .dropdown-item {
  label.text {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.HaiModalDialog.HaiDialog[theme='argon'].preset-manage .modal-content .HaiModal .modal-body {
  height: 550px;
}
.multilevel {
  .subMenu {
    .dropdown-item.dropdown-item.dropdown-item .text {
      max-width: 370px;
      align-items: center;
      display: flex;

      .preset-name-container {
        min-width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .HaiOverlayTrigger {
        display: contents;
      }
    }
  }
}
.HaiModalDialog.HaiDialog[theme='argon'].preset-manage .modal-content .HaiModal,
.HaiModalDialog.HaiDialog[theme='argon'].save-preset .modal-content .HaiModal {
  .modal-dialog-body-content {
    overflow: hidden;

    .preset-drag-n-drop {
      padding-top: 15px;
    }
  }
  .modal-header.modal-header.modal-header {
    padding-bottom: 0.5em;
  }
  .startup-dropdown .childrenContainer.overflowing > a:first-child {
    border-bottom: 2px solid $haiui-gray-02;
  }

  .radio-button-label-text {
    white-space: nowrap;
  }
  .HaiRadioButton > label {
    white-space: nowrap;
  }
  .info {
    display: flex;
  }
  .datatable-container .HaiDataTable[theme='argon'] .preset-row {
    .data-table-cell .text-data {
      max-width: 370px;
      align-items: center;
      display: flex;

      .preset-name-container {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .HaiOverlayTrigger {
        display: contents;
      }
    }
    background-color: #2b2b33; // UX unique color...
  }
  .datatable-container .HaiDataTable[theme='argon'] .data-table-header {
    background-color: $haiui-gray-01;
  }

  .autosave-container .HaiControlLabel {
    top: 10px;
    width: 140px;
    position: relative;
    div {
      float: right;
    }
  }

  .preset-autosave.preset-autosave.preset-autosave {
    display: inline-block;
    padding-left: 15px;
    top: 2px;
    position: relative;
  }
  .presets-datatable {
    thead {
      position: sticky;
    }

    tbody {
      &.datatable-header-bulk-actions-container {
        height: 2.875rem;
      }

      max-height: 205px;
      margin-bottom: 20px;
      .HaiTextInlineEditor {
        margin-left: 20px;
      }

      .delete-row {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .data-table-text {
        width: 100%;
      }
    }
  }
}

.startup-tag.startup-tag.startup-tag:first-of-type {
  margin-left: 10px;
}

// hack for presets main menu
body
  .HaiDropdownMenu[theme='argon']
  .subMenu
  .HaiDropdownMenuItem
  svg.green-check.green-check.green-check {
  margin-left: 10px !important; // HACK TO OVERRIDE HAIUI
  fill: $haiui-green-01 !important;
}

// hack for presets manage menu
svg.green-check.green-check.green-check {
  margin-left: 10px !important; // HACK TO OVERRIDE HAIUI
  fill: $haiui-green-01 !important;
}
