@import 'assets/scss/theme.scss';

.settings-header {
  width: 100%;
  display: flex;
  margin-top: 0.5rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  height: 3.5rem;

  .buttons {
    display: flex;
    gap: 1rem;
  }
}
